<template>
  <div class="win_bid_product">
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <!-- <el-autocomplete v-model="bidPeriod" :fetch-suggestions="getbidPeriodPagelist" placeholder="请搜索标期" @select="onSearch" value-key="bidPeriod" @change="onSearch"></el-autocomplete> -->
        <el-input v-model="tendersLabel" placeholder="搜索标号" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-select v-model="saleStatus" placeholder="上下架状态" @change="onSearch" clearable>
          <el-option label="下架" value="0"></el-option>
          <el-option label="上架" value="1"></el-option>
        </el-select>
        <el-input v-model="supplierName" placeholder="搜索供应商名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-input v-model="productName" placeholder="搜索商品名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-input v-model="barCode" placeholder="搜索条形码" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-select v-model="depositStatus" placeholder="是否缴纳保证金" @change="onSearch" clearable>
          <el-option label="待缴纳" value="0"></el-option>
          <el-option label="已缴纳" value="1"></el-option>
        </el-select>
        <el-button type="primary" @click="onEdit">批量修改中标有效期</el-button>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column prop="number" label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column prop="" label="图片" min-width="100">
            <template slot-scope="scope">
              <el-image style="width: 60px; height: 60px; border-radius: 6px" :src="scope.row.picUrlList && scope.row.picUrlList.length > 0 ? scope.row.picUrlList[0] : ''" fit="cover" @mouseover="previewImage(scope.row.picUrlList)"></el-image>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="tendersLabel" label="标号" min-width="100"></el-table-column>
          <!-- <el-table-column show-overflow-tooltip prop="serialId" label="商品编号" min-width="150"></el-table-column> -->
          <el-table-column show-overflow-tooltip prop="productName" label="商品名称" min-width="150"></el-table-column>
          <el-table-column show-overflow-tooltip prop="barCode" label="商品条形码" min-width="150"></el-table-column>
          <el-table-column show-overflow-tooltip prop="categoryName" label="分类名称" min-width="100"></el-table-column>
          <el-table-column show-overflow-tooltip prop="specs" label="规格" min-width="100"></el-table-column>
          <el-table-column show-overflow-tooltip prop="enterBox" label="入箱数" min-width="100"></el-table-column>
          <el-table-column show-overflow-tooltip prop="biddingPrice" label="供货价(元)" min-width="120"></el-table-column>
          <el-table-column show-overflow-tooltip prop="suggestPrice" label="建议零售价(元)" min-width="160"></el-table-column>
          <el-table-column show-overflow-tooltip prop="brand" label="品牌" min-width="120"></el-table-column>
          <el-table-column show-overflow-tooltip prop="factoryName" label="厂家" min-width="120"></el-table-column>
          <el-table-column show-overflow-tooltip prop="supplierName" label="供应商名称" min-width="150"></el-table-column>
          <el-table-column show-overflow-tooltip prop="validityPeriod" label="保质期(天)" min-width="100"></el-table-column>
          <el-table-column show-overflow-tooltip prop="" label="中标有效期" min-width="200">
            <template slot-scope="scope">{{ scope.row.startTime }}至{{ scope.row.endTime }}</template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="" label="是否缴纳保证金" min-width="130" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.depositStatus == 0" style="color: red">待缴纳</span>
              <span v-if="scope.row.depositStatus == 1">已缴纳</span>
            </template>
          </el-table-column>
          <el-table-column label="上下架状态" header-align="center" align="center" width="120" fixed="right">
            <template v-if="scope.row.depositStatus == 1" slot-scope="scope">
              <el-switch v-model="scope.row.switch" active-color="#069BBC" inactive-color="#E2E2E2" @change="switchChange(scope.row)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="150" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button type="primary" plain @click="onEditTime(scope.row)">修改中标有效期</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>

    <el-dialog :title="dialogtitle" :visible.sync="dialogVisible" :before-close="onCancel">
      <el-form :model="ruleForm" ref="ruleForm" label-width="160px" class="demo-ruleForm">
        <el-row>
          <el-col :span="10">
            <el-form-item v-if="dialogtitle == '批量修改中标有效期'" label="供应商名称" prop="supplierName">
              <el-select v-model="ruleForm.supplierId" placeholder="请选择供应商名称" @change="changeSupplierName" clearable>
                <el-option v-for="(item, index) in options1" :key="index" :label="item.supplierName" :value="item.supplierId"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="中标有效截止日期" prop="endTime">
              <el-date-picker v-model="ruleForm.endTime" type="date" placeholder="请选择日期" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item v-if="dialogtitle == '批量修改中标有效期'" label="标号" prop="tendersLabel">
              <el-select v-model="ruleForm.tendersLabel" placeholder="请选择标号" clearable :disabled="ruleForm.supplierId ? false : true">
                <el-option v-for="(item, index) in options2" :key="index" :label="item.tendersLabel" :value="item.tendersLabel"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog>

    <el-image-viewer
      v-if="showViewer"
      :on-close="
        () => {
          showViewer = false
        }
      "
      :url-list="imgList"
    ></el-image-viewer>
  </div>
</template>

<script>
function creatForm() {
  return {
    id: null,
    supplierId: "",
    supplierName: "",
    tendersLabel: "",
    endTime: "",
  }
}
export default {
  components: {
    "el-image-viewer": () => import("element-ui/packages/image/src/image-viewer"),
  },
  data() {
    return {
      loading: false,
      // bidPeriod: "",
      tendersLabel: "",
      supplierName: "",
      productName: "",
      depositStatus: "",
      saleStatus: "",
      barCode: "",
      tableData: [],
      bidPeriodlist: [],
      options1: [],
      options2: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）

      // 图片预览
      showViewer: false,
      imgList: [],

      dialogSupplierName: "",
      ruleForm: creatForm(),
      dialogtitle: "修改中标有效期",
      submitLoding: false,
      dialogVisible: false,
    }
  },
  created() {
    this.tendersLabel = this.$route.query.id || ""
    this.supplierName = this.$route.query.name || ""
    this.getList()
  },
  methods: {
    getSupplierList() {
      this.$axios.get(this.$api.supplierInfoList).then(res => {
        this.options1 = res.data.result
      })
    },
    getTendersLabelList() {
      this.$axios
        .get(this.$api.tendersInfoList, {
          params: { supplierName: this.ruleForm.supplierName || "" },
        })
        .then(res => {
          this.options2 = res.data.result
        })
    },
    getList() {
      this.loading = true
      this.$axios
        .get(this.$api.supplierBiddingProductDetail, {
          params: {
            supplierId: this.$route.query.id,
            bidPeriod: this.bidPeriod,
            tendersLabel: this.tendersLabel,
            productName: this.productName,
            supplierName: this.supplierName,
            depositStatus: this.depositStatus,
            saleStatus: this.saleStatus,
            barCode: this.barCode,
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list?.map(e => {
              return {
                ...e,
                switch: e.saleStatus == 0 ? false : true,
                pic: e.picUrlList ? e.picUrlList[0] : "",
              }
            })
            this.totalItemsCount = res.data.result.totalCount
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 上下架
    switchChange(row) {
      const originalSwitch = row.switch
      this.$confirm(`即将${row.switch ? "上架" : "下架"}该商品，是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.supplierBiddingProductUpAndDown, {
              id: row.id,
              saleStatus: row.switch ? "1" : "0",
            })
            .then(res => {
              if (res.data.code == 100) {
                this.$set(row, "switch", originalSwitch)
                this.$message.success("操作成功")
              } else {
                this.$set(row, "switch", !originalSwitch)
              }
            })
            .catch(() => {
              this.$set(row, "switch", !originalSwitch)
            })
        })
        .catch(() => {
          this.$set(row, "switch", !originalSwitch)
        })
    },
    changeSupplierName(el) {
      this.ruleForm.supplierName = this.options1.find(e => e.supplierId == el).supplierName || ""
      this.getTendersLabelList()
    },
    onEdit() {
      this.getSupplierList()
      this.dialogtitle = "批量修改中标有效期"
      this.dialogVisible = true
    },
    onEditTime(row) {
      this.ruleForm.id = row.id
      this.dialogtitle = "修改中标有效期"
      this.dialogVisible = true
    },
    onCancel() {
      this.$refs.ruleForm.resetFields()
      this.ruleForm = creatForm()
      this.dialogVisible = false
    },
    submitForm() {
      let api = ""
      let params = {}
      if (this.dialogtitle == "修改中标有效期") {
        api = this.$api.updateBiddingProductTime
        params = {
          id: this.ruleForm.id,
          endTime: this.ruleForm.endTime,
        }
      } else {
        api = this.$api.batchUpdateBiddingProductTimeDto
        params = Object.assign({}, this.ruleForm)
      }
      this.$axios.post(api, params).then(res => {
        if (res.data.code == 100) {
          this.$message.success("修改成功")
          this.$refs.ruleForm.resetFields()
          this.ruleForm = creatForm()
          this.getList()
          this.dialogVisible = false
        }
      })
    },
    onSearch() {
      this.tableData = []
      this.currentPage = 1
      this.getList()
    },
    // 图片预览
    previewImage(url) {
      this.imgList = url
      this.showViewer = true
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e
      this.getList()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getList()
    },
  },
}
</script>

<style scoped lang="scss">
.win_bid_product {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;

    .el-autocomplete,
    .el-select,
    .el-button,
    .el-input {
      margin-bottom: 20px;
      margin-left: 20px;
    }
    .el-select,
    .el-input {
      width: 200px;
    }
    ::v-deep .el-input__inner {
      border-radius: 10px;
    }
  }
}
</style>
